import styles from './index.module.scss'
import UserLoginBox from './user-login-box'
import SearchInput from './search-input'
import cm from 'classnames'
import Button from '../../button'
import Link from 'next/link'
import { userInfo, h5DownloadModal } from '@/store'
import { useShowLogin, useVipDialog } from '@/utils/hooks'
import { buyVipReport, openPayModalReport } from '@/utils/report'
import { useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

const Index = ({ vipData }) => {
  const setShowLogin = useShowLogin()
  const showVipDialog = useVipDialog()
  const [currIndex, setCurrIndex] = useState(0)
  // const { vipData } = props

  // const vipData =  vipData
  const user = useRecoilValue(userInfo)
  // console.log("===== vipData =====",vipData)
  const openDialog = index => {
    const isLogin = localStorage.getItem('token')
    openPayModalReport({
      isLogin: isLogin ? 'true' : 'false',
      package_type: vipData[index].pDesc,
      user_type: user?.isVip ? '会员' : '非会员'
    })
    if (!isLogin) {
      setShowLogin(true)
      return
    }
    buyVipReport({
      package_type: vipData[index].pDesc,
      user_type: user.isVip ? '会员' : '非会员'
    })
    showVipDialog(index)
  }

  const quanyiArr = [
    {
      icon: '/api/images/v-line.webp',
      title: '最优线路',
      desc: '会员独享高清线路，点播资源秒开无卡顿。画质更细腻，观看体验瞬间提升！'
    },
    {
      icon: '/api/images/v-live.webp',
      title: '电视直播',
      desc: '会员畅享流畅电视直播，500+频道任你选。直击热门节目，精彩不断，娱乐生活全方位升级！'
    },
    {
      icon: '/api/images/v-source.webp',
      title: '资源下载',
      desc: '随时随地的下载视频，然后随时随地观看，观影不受网络限制。留住精彩时刻，随时随地畅享！'
    },
    {
      icon: '/api/images/v-rate.webp',
      title: '倍速播放',
      desc: '视频节奏由我掌控！会员独享全部倍速，观看更自由，随心调节观影体验！'
    }
  ]

  return (
    <div className={cm(styles.rightMenu)}>
      <SearchInput />
      {typeof user?.token !== 'undefined' && user.isLogin && (
        <Button hover="scaleImg" className={cm(styles.rightBtn, styles.vip)}>
          <Link link href="/vip">
            <img loading="lazy" className={styles.icon} src="/api/images/vip-active.png" title="会员" alt="会员" />
            {/* <div>会员</div> */}
          </Link>
          <div className={styles.dialogVip}>
            <div className={styles.quanyi}>
              {quanyiArr.map((item, index) => {
                return (
                  <div className={styles.itemX} key={index}>
                    <img src={item.icon} className={styles.icon} alt={item.title} />
                    <div>{item.title}</div>
                  </div>
                )
              })}
            </div>
            <div className={styles.tv}>
              <div className={styles.title}>
                {user.isVip ? '尊敬的用户' : '用户'} ： {user.email}
              </div>
              <div className={styles.btn} onClick={() => openDialog(0)}>
                {user.isVip ? '续费会员' : '加入会员'}
              </div>
            </div>
            <div className={styles.line}></div>
            <div className={styles.anzhuo}>
              <div className={styles.left}>
                <div className={styles.title}>扫码下载App，免费领取会员权益</div>
                <div className={styles.info}>仅限注册新用户</div>
              </div>
              <img src="/api/images/join_phone.png" className={styles.right} alt="加入群聊" />
            </div>
          </div>
        </Button>
      )}

      {/* TG群聊 */}
      <Button
        rel="nofollow"
        hover="scaleImg"
        link
        target="_blank"
        href="https://t.me/+CKkE1hC7_3dlYTk1"
        className={cm(styles.rightBtn, styles.join)}>
        <img className={styles.icon} loading="lazy" src="/api/images/join" alt="加入群聊" title="加入群聊" />
        {/* <div>TG群</div> */}
        <div className={styles.joinClub}>
          <img src="/api/images/join_phone.png" className={styles.joinImg} alt="加入群聊" />
          <div className={styles.joinText}> 扫码加入TG群聊</div>
        </div>
      </Button>

      {/* 历史记录 */}
      {/* <Button hover="scaleImg" link href="/history" className={cm(styles.rightBtn, styles.download)}>
        <img loading="lazy" className={styles.icon} src="/api/images/history" title="历史" alt="历史" />
        <div>历史</div>
      </Button> */}

      {/* 信息框 */}
      <UserLoginBox />

      {/* 下载app */}
      <Button hover="scaleImg" link href="/download" className={cm(styles.downloadBtn, styles.download)}>
        {/* <img loading="lazy" className={styles.icon2} src="/api/images/down" title="下载app" alt="下载app" /> */}
        <div className={styles.app}>TV 下载</div>
        <div className={styles.dialog}>
          <div className={styles.tv}>
            <div className={styles.title}>使用TV客户端，享更佳视听盛宴</div>
            <div className={styles.btn}>立即下载TV端</div>
          </div>
          <div className={styles.line}></div>
          <div className={styles.anzhuo}>
            <div className={styles.left}>
              <div className={styles.title}>扫码下载App，免费领取会员权益</div>
              <div className={styles.info}>仅限注册新用户</div>
            </div>
            <img src="/api/images/join_phone.png" className={styles.right} alt="加入群聊" />
          </div>
        </div>
      </Button>
    </div>
  )
}

export default Index
