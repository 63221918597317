import styles from './index.module.scss'
import cm from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'

const navList = [
  { label: '推荐', url: '/' },
  { label: '电影', url: '/movie' },
  { label: '电视剧', url: '/drama' },
  { label: '直播', url: '/live' },
  { label: '体育', url: '/sports' },
  { label: '综艺', url: '/variety' },
  { label: '动漫', url: '/animation' },
  { label: '儿童', url: '/children' },
  { label: '纪录片', url: '/documentary' }

  // { label: '节目单', url: '/live/epg/all' }
]

// const navList = [
//   { label: '首页', url: '/' },
//   { label: '电影', url: '/movie' },
//   { label: '电视剧', url: '/drama' },
//   { label: '综艺', url: '/variety' },
//   { label: '动漫', url: '/animation' },
//   { label: '电视直播', url: '/live' },
//   // { label: '体育', url: '/sports' }
//   // { label: '节目单', url: '/live/epg/all' }
// ]

const Index = ({ style }) => {
  const { asPath, pathname, query, currentUrl } = useRouter()
  const currPath = '/' + pathname.split('/')[1]
  const isCurrUrlActive = url => {
    return currPath === url || '/' + query.tab === url || currentUrl === url
  }

  const urlArr = ['/movie', '/drama', '/variety', '/animation', '/live', '/sports']
  const itemClass = item =>
    cm(styles.li, {
      [styles.liActive]: isCurrUrlActive(item.url)
    })
  return (
    <>
      <nav className={styles.nav} style={{ ...style }}>
        {navList.map((item, index) => {
          return (
            <Link href={item.url} key={index} className={itemClass(item, index)} onClick={() => sessionStorage.clear()}>
              {urlArr.includes(asPath) && isCurrUrlActive(item.url) ? <h1>{item.label}</h1> : <p>{item.label}</p>}
            </Link>
          )
        })}
      </nav>
    </>
  )
}

export default Index
