const env = process.env.NEXT_PUBLIC_ENV

const config = {
  test: {
    api: 'https://api.jzcert.com'
  },
  prod: {
    api: 'https://app.ymedium.top'
    // api: 'https://api.jzcert.com'
  }
}

export default config[env]
