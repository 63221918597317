import styles from './index.module.scss'
import { useRouter } from 'next/router'
import { useState, useRef, useEffect, memo } from 'react'
import SearchBox from './search-box'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { hotSearchData, showSearchFocus } from '@/store'
import cm from 'classnames'

// 本地持久化已搜素过的关键字
const saveKeyword = word => {
  const keywords = JSON.parse(localStorage.getItem('keywords') || '[]')
  keywords.unshift(word)
  localStorage.setItem('keywords', JSON.stringify([...new Set(keywords.slice(0, 15))]))
}

const Index = () => {
  const setShowSearchData = useSetRecoilState(showSearchFocus)
  const topData = useRecoilValue(hotSearchData)
  const router = useRouter()
  const [inputText, setText] = useState(router.query.word || '')
  const [focus, setFocus] = useState(false)
  const [openInput, setOpenInput] = useState(true)
  const [searchArr, setSearchArr] = useState([])







  const handleEvent = e => {
    e.stopPropagation()
    setText(e.currentTarget.value)
  }
  const ref = useRef(null)
  const toSearch = () => {
    const text = inputText.trim()
    if (text) {
      saveKeyword(text)
      setFocus(false)
      ref.current.blur()
      if (router.pathname === '/search') {
        router.replace('/search?word=' + text)
      } else {
        router.push('/search?word=' + text)
      }
    }
  }
  const openSearch = e => {
    e.stopPropagation()
    setOpenInput(true)
    setFocus(true)
    setShowSearchData(true)
    ref.current.focus()
    setSearchArr(JSON.parse(localStorage.getItem('keywords') || '[]'))
  }


  
  const keyDown = e => {
    e.keyCode === 13 && toSearch()
  }
  useEffect(() => {
    const clickBlur = () => {
      setFocus(false)
      setOpenInput(true)
    }
    window.addEventListener('click', clickBlur)
    window.addEventListener('blur', clickBlur)
    return () => {
      window.removeEventListener('click', clickBlur)
      window.removeEventListener('blur', clickBlur)
    }
  }, [])

  const expend = e => {
    e.stopPropagation()
    if (openInput) {
      toSearch()
    } else {
      openSearch(e)
    }
  }
  return (
    <div className={cm(styles.input, openInput ? styles.inputActive : '')}>
      <input
        ref={ref}
        type="text"
        onClick={openSearch}
        placeholder="搜索片名"
        maxLength={20}
        value={inputText}
        onBlur={() => setShowSearchData(false)}
        onInput={handleEvent}
        onKeyUp={keyDown}
      />
      <span className={styles.line}></span>
      <img loading="lazy" src="/api/images/h-search" alt="搜索" title="搜索" className={styles.img} onClick={expend} />
      {focus && <SearchBox searchArr={searchArr} clear={() => setSearchArr([])} topData={topData} setText={setText} />}
    </div>
  )
}

export default memo(Index)
