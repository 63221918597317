import request from '@/services/request'

/**
 * 拉取套餐页数据
 * */
export const getVipData = async (options = {}) => {
  return await request.get('/api/v1/pay/product', {}, options)
}

/**
 *  预下单接口，微信&支付宝二维码生成支付二维码
 *  pCode string 套餐code
 *  sign 签名（ 直接把套餐code的value md5一下，然后得到32位小写字符串
 *  payWay  支付方式 1=wxpay,2=alipay,3=paypal
 * */
export const generatePayQrCode = async (data, options = {}) => {
  return await request.post('/api/v1/pay/qrcode', data, options)
}

// 直接下单
export const payCenter = async (data, options = {}) => {
  return await request.post('/api/v1/pay/paypal/choose', data, options)
}

// 直接下单
export const payDirect = async (data, options = {}) => {
  return await request.post('/api/v1/pay/direct/create', data, options)
}

/**
 * 获取支付结果
 * orderId 订单号
 * sign 签名
 * */
export const getPayResult = async (data, options = {}) => {
  return await request.post('/api/v1/pay/result', data, options)
}

// 马来西亚支付下单
export const maLaiPayOrder = async (data, options = {}) => {
  return await request.post('/api/v1/pay/cools/create', data, options)
}

// 支付成功，清除订单缓存
export const clearOrderCache = async () => {
  return await request.post('/api/v1/pay/cools/clean')
}
