export const FILM_OBJ = {
  movie: '电影',
  drama: '电视剧',
  variety: '综艺',
  animation: '动漫',
  live: '电视直播',
  children:'动画片',
  documentary:'纪录片'
}

export const FILM_OBJ_FILTER = {
  movie: ['电影片库', 'all'],
  drama: ['电视剧片库', 'all'],
  variety: ['综艺片库', 'all'],
  animation: ['动漫片库', 'all'],
  children: ['少儿', 'all'],
  documentary: ['纪录片', 'all']
}
export const REVERSE_FILM_OBJ_FILTER = Object.keys(FILM_OBJ_FILTER).reduce((target, curr) => {
  target[FILM_OBJ_FILTER[curr]] = curr
  return target
}, {})

export const FILM_URL = Object.keys(FILM_OBJ).reduce((target, curr) => {
  target[FILM_OBJ[curr]] = '/' + curr
  return target
}, {})

export const FILE_URL = ['movie', 'drama', 'variety', 'animation','children','documentary']

export const PAGE_INFO = {
  movie: {
    title: '电影在线看 - 最新热门电影免费观看 - 华视界',
    desc: '华视界汇聚海量电影资源，你可以免费观看最新的热门电影，观看各地各类最新电影，从动作冒险到浪漫爱情，从喜剧到恐怖片等，第一时间锁定院线大片。'
  },
  drama: {
    title: '电视剧在线看 - 最新热门电视剧免费观看 - 华视界',
    desc: '华视界收录丰富剧集，包括陆剧、韩剧、台剧、美剧、日剧、泰剧等，同步免费追更新热电视剧，满足各地需求，高清流畅，是全球华人的追剧首选。'
  },
  variety: {
    title: '综艺在线看 - 最新热门综艺免费观看 - 华视界',
    desc: '想观看最新的综艺节目吗？我们提供最全的综艺节目资源，热门综艺节目及上新综艺免费在线观看。脱口秀、真人秀、选秀节目应有尽有，随时随地尽情享受娱乐。点击进入我们的首页，开始您的综艺之旅！'
  },
  animation: {
    title: '动漫在线看 - 新番追更老漫补番免费观看 - 华视界',
    desc: '华视界为所有动漫迷提供高质量的动漫资源，涵盖丰富的日漫、国漫以及美漫等。更新及时，免费观看，是追番补番的最佳聚集地。'
  },
  documentary: {
    title: '纪录片在线看 - 经典最新热门纪录片免费观看 - 华视界',
    desc: '华视界为所有动漫迷提供高质量的动漫资源，涵盖丰富的日漫、国漫以及美漫等。更新及时，免费观看，是追番补番的最佳聚集地。'
  },
  children: {
    title: '少儿动画在线看 - 最新热门少儿动画免费观看 - 华视界',
    desc: '华视界为所有动漫迷提供高质量的动漫资源，涵盖丰富的日漫、国漫以及美漫等。更新及时，免费观看，是追番补番的最佳聚集地。'
  },

}

// 电视剧地区映射表,用在SEO中
const AREA_MAP = {
  大陆: '国剧',
  中国大陆: '国剧',
  香港: '港剧',
  台湾: '台剧',
  韩国: '韩剧',
  日本: '日剧',
  英国: '英剧',
  美国: '美剧',
  泰国: '泰剧',
  印度: '印剧',
  西班牙: '西班牙电视剧',
  加拿大: '加拿大电视剧',
  法国: '法国电视剧',
  印度: '印度电视剧',
  澳大利亚: '澳大利亚电视剧',
  其他: '其他地区电视剧'
}
export const AREA_GET = area => AREA_MAP[area] || area + '剧'

// 地区英文映射表
const AREA_MAP_EN = {
  中国大陆: 'cn',
  香港: 'hk',
  台湾: 'tw',
  美国: 'us',
  日本: 'jp',
  韩国: 'kr',
  英国: 'gb',
  泰国: 'th',
  西班牙: 'sp',
  加拿大: 'ca',
  法国: 'fr',
  印度: 'in',
  澳大利亚: 'au',
  其他地区: 'others',
  其他: 'others'
}
// type:1-正的，2-反的
export const AREA_MAP_EN_FUNC = (area, type = 1) => {
  const reverse = Object.keys(AREA_MAP_EN).reduce((target, curr) => {
    target[AREA_MAP_EN[curr]] = curr
    return target
  }, {})
  if (type === 1) {
    return AREA_MAP_EN[area] || area
  }
  return reverse[area] || area
}

export const COUNTRY_URL = Object.keys(AREA_MAP_EN).reduce((target, curr) => {
  target.push(AREA_MAP_EN[curr])
  return target
}, [])

// 地区映射表，用在搜索中
const AREA_MAP_SEARCH = {
  movie: {
    最新大陆电影: 'cn',
    最新美国电影: 'us',
    最新欧美电影: 'us',
    最新韩国电影: 'kr',
    最新日本电影: 'jp',
    最新泰国电影: 'th',
    最新台湾电影: 'tw',
    最新香港电影: 'hk',
    最新英国电影: 'uk',
    最新西班牙电影: 'sp',
    最新加拿大电影: 'ca',
    最新法国电影: 'fr',
    最新印度电影: 'in',
    最新澳大利亚电影: 'au'
  },
  drama: {
    最新美剧: 'us',
    最新美剧: 'us',
    最新韩剧: 'kr',
    最新日剧: 'jp',
    最新泰剧: 'th',
    最新国产剧: 'cn',
    最新港剧: 'hk',
    最新台剧: 'tw',
    最新英剧: 'uk'
  },
  variety: {
    大陆综艺: 'cn',
    最新大陆综艺: 'cn',
    韩国综艺: 'kr',
    最新韩国综艺: 'kr',
    日本综艺: 'jp',
    最新日本综艺: 'jp',
    美国综艺: 'us',
    最新美国综艺: 'us',
    台湾综艺: 'tw',
    最新台湾综艺: 'tw',
    香港综艺: 'hk',
    最新香港综艺: 'hk',
    英国综艺: 'uk',
    最新英国综艺: 'uk',
    泰国综艺: 'th',
    最新泰国综艺: 'th',
    西班牙综艺: 'sp',
    最新西班牙综艺: 'sp',
    加拿大综艺: 'ca',
    最新加拿大综艺: 'ca',
    法国综艺: 'fr',
    最新法国综艺: 'fr',
    印度综艺: 'in',
    最新印度综艺: 'in',
    澳大利亚综艺: 'au',
    最新澳大利亚综艺: 'au'
  },
  animation: {
    国产动漫: 'cn',
    最新国产动漫: 'cn',
    日本动漫: 'jp',
    最新日本动漫: 'jp',
    韩国动漫: 'kr',
    最新韩国动漫: 'kr',
    美国动漫: 'us',
    最新美国动漫: 'us',
    英国动漫: 'uk',
    最新英国动漫: 'uk',
    泰国动漫: 'th',
    最新泰国动漫: 'th',
    台湾动漫: 'tw',
    最新台湾动漫: 'tw',
    香港动漫: 'hk',
    最新香港动漫: 'hk'
  }
}

export const AREA_SEARCH_GET = (type, area) => AREA_MAP_SEARCH[type][area] || area

//  下载地址

export const DOWNLOAD_URL = {
  tv: 'https://static.ymedium.top/app/tv.apk',
  phone: 'https://static.ymedium.top/app/phone.apk'
}

// 体育相关常量 //
export const SPORT_INFO = [
  {
    url: 'soccer',
    name: '足球',
    title: '足球直播 - 五大联赛赛事实况免费直播 - 华视界',
    desc: '华视界足球直播提供免费热门足球赛事全程精彩实况直播。足球直播频道为您提供高清画质、实时比分和赛事解说，让您身临其境感受足球魅力。立即加入我们，与全球球迷一同分享足球的激情！不容错过的赛事包括英超、西甲、德甲、意甲、欧冠等精彩对决。'
  },
  {
    url: 'events',
    name: '赛会',
    title: '赛会直播 - 大型运动会全赛程免费看 - 华视界',
    desc: '华视界赛会赛事直播提供免费的热门大型体育赛事直播，如成都大运会等全球性体育赛事全赛程高清实时放送，直播后可迅速生成录播，更有app可长期稳定观看！'
  },
  {
    url: 'basketball',
    name: '篮球',
    title: '篮球直播 - NBA等热门赛事实况免费直播 - 华视界',
    desc: '华视界篮球直播提供免费的篮球赛事全程精彩实况直播，比赛包括最热赛事如NBA直播，CBA直播以及村BA等网络热门赛事。直播全场高清实时放送，直播结束后会迅速生成录播，更有app长期稳定观看！'
  },
  {
    url: 'volleyball',
    name: '排球',
    title: '排球直播 - 高清排球比赛实况免费直播 - 华视界',
    desc: '华视界排球直播提供免费的排球联赛直播以及比分实时报道，直播全场高清实时放送，直播结束后会迅速生成录播，更有app长期稳定观看！'
  },
  {
    url: 'boxing',
    name: '拳击',
    title: '拳击直播 - 拳击比赛高清免费直播 - 华视界',
    desc: '华视界拳击直播提供免费的拳击比赛，包括UFC数字赛及格斗之夜等直播全场高清实时放送，直播结束后会迅速生成录播，更有app可长期稳定观看！'
  },
  {
    url: 'badminton',
    name: '羽毛球',
    title: '羽毛球赛事直播 - 羽球比赛高清直播免费看 - 华视界',
    desc: '华视界羽毛球直播提供免费的羽毛球比赛直播，高清实时放送，直播后可迅速生成录播，还包含最全的赛事项目，更有app可长期稳定观看！'
  },
  {
    url: 'table-tennis',
    name: '乒乓球',
    title: '乒乓球赛事直播 - 比赛高清直播免费看 - 华视界',
    desc: '华视界乒乓球直播提供免费的羽毛球比赛直播，高清实时放送，直播后可迅速生成录播，还包含最全的赛事项目，更有app可长期稳定观看！'
  },
  {
    url: 'tennis',
    name: '网球',
    title: '网球赛事直播 - 比赛高清直播免费看 - 华视界',
    desc: '华视界网球直播提供免费的网球比赛直播，高清实时放送，直播后可迅速生成录播，还包含最全的赛事项目，更有app可长期稳定观看！'
  },
  {
    url: 'others',
    name: '综合赛事',
    title: '综合赛事直播 - 热门赛事直播免费看 - 华视界',
    desc: '华视界综合赛事直播提供免费的热门比赛直播，包括斯诺克、赛马、田径、高尔夫等高清实时放送，直播后可迅速生成录播，更有app可长期稳定观看！'
  },
  {
    url: 'esports',
    name: '电竞',
    title: '电竞赛事直播 - 热门赛事直播免费看 - 华视界',
    desc: '华视界电竞赛事直播，提供热门电竞比赛，如英雄联盟、王者荣耀、和平精英等热门游戏的热门赛事，如LPL、MSI及S赛等，更有app可长期稳定观看！'
  }
]

export const SPORTS_PATHS = SPORT_INFO.reduce((final, target) => {
  final.push(target.url)
  return final
}, [])

//固定的热门赛程
export const HOT_RACE_ARR = [
  {
    name: '欧洲杯',
    url: 'euro2024',
    icon: '/api/images/hot1.webp'
  },
  {
    name: 'NBA',
    url: 'nba',
    icon: '/api/images/hot2'
  },
  {
    name: '英超',
    url: 'premier-league',
    icon: '/api/images/hot3'
  },
  {
    name: '西甲',
    url: 'la-liga',
    icon: '/api/images/hot4'
  },
  {
    name: '意甲',
    url: 'serie-a',
    icon: '/api/images/hot5'
  },
  {
    name: '法国网球公开赛',
    url: 'french-open',
    icon: '/api/images/hot6'
  },
  {
    name: '德甲',
    url: 'bundesliga',
    icon: '/api/images/hot7'
  },
  {
    name: 'WNBA',
    url: 'wnba',
    icon: '/api/images/hot8'
  },
  {
    name: 'UFC格斗之夜',
    url: 'ufc-fight-night',
    icon: '/api/images/hot9'
  },
  {
    name: '法甲',
    url: 'ligue1',
    icon: '/api/images/hot10'
  },
  {
    name: 'ATP',
    url: 'atp',
    icon: '/api/images/hot11'
  },
  {
    name: 'LPL',
    url: 'lpl',
    icon: '/api/images/hot12'
  },
  {
    name: '中超',
    url: 'csl',
    icon: '/api/images/hot13'
  },
  {
    name: 'CBA',
    url: 'cba',
    icon: '/api/images/hot14'
  },
  {
    name: '女足世界杯',
    url: 'fifa-women-world-cup',
    icon: '/api/images/hot15'
  },
  {
    name: 'UFC数字赛',
    url: 'ufc-ppv',
    icon: '/api/images/hot16'
  }
]

export const AUTO_KEY = 'fsro3uro89328832'

export const publicKeyPEM = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAz8ylu8lBLANVx0krztpe
4OzV2e/Y7EcYaSNcM1KAahHJMV1jj/Qji1AIBKPSjobEv/8Qxh0mG4wy9Rn2e48b
x9p/NVMu4R3y00qbmIgmmK2G6VypX+4bNx8BE127WklvFc0lEp/M7guUddF2OB7o
msg4Et3LEEU3Yff7QFOq+3GtlttMDT//bKXU38a5ytWjkEhc0ikmeOLSRBsKlFKP
YmsurFiD9mpzJPbv60nNdKkCsQHnFIgCrDTYhuOyqEnpCMQT6FNXrsuGuGYwPYgr
kMsDhFwmSOO88TvajHCUp0Kq+EV/KsXPL4kYiZKWUu5Qstrrm8hFtKLkzoiP4jvj
NQIDAQAB
-----END PUBLIC KEY-----
`

export const registers = 'HVTS9NFVIeWdUxbf'
