import { useEffect, useState } from 'react'
import styles from './index.module.scss'
import cm from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import Button from '../../button'

const navList = [
  { label: '推荐', url: '/' },
  { label: '电影', url: '/movie' },
  { label: '电视剧', url: '/drama' },
  { label: '直播', url: '/live' },
  { label: '体育', url: '/sports' },
  { label: '综艺', url: '/variety' },
  { label: '动漫', url: '/animation' },
  { label: '儿童', url: '/children' },
  { label: '纪录片', url: '/documentary' }
  // { label: '节目单', url: '/live/epg/all' }
]

const Index = props => {
  const { asPath, pathname, query } = useRouter()
  const [count, setCount] = useState(6)
  const { currentUrl } = props
  const currPath = '/' + pathname.split('/')[1]
  const isCurrUrlActive = url => {
    return currentUrl === url || currPath === url || '/' + query.tab === url
  }

  const urlArr = ['/movie', '/drama', '/variety', '/animation', '/live', '/sports']
  const itemClass = item =>
    cm(styles.li, {
      [styles.liActive]: isCurrUrlActive(item.url)
    })

  useEffect(() => {
    const getWindowWidth = () => {
      const width = window.innerWidth
      if (width > 1500) {
        setCount(5)
      } else if (width >= 1400) {
        setCount(4)
      } else if (width >= 1255) {
        setCount(3)
      } else if (width >= 1050) {
        setCount(1)
      } else {
        setCount(1)
      }

      // if (width > 1400) {
      //   setCount(6)
      // } else if (width > 1255) {
      //   setCount(5)
      // } else if (width > 1180) {
      //   setCount(4)
      // } else {
      //   setCount(1)
      // }
    }
    getWindowWidth()
    window.addEventListener('resize', getWindowWidth)
    return () => {
      window.removeEventListener('resize', getWindowWidth)
    }
  }, [])

  return (
    <>
      <nav className={cm(styles.nav, styles.navPhone)}>
        {navList.slice(0, count).map((item, index) => {
          return (
            <Link href={item.url} key={index} className={itemClass(item, index)} onClick={() => sessionStorage.clear()}>
              {urlArr.includes(asPath) && isCurrUrlActive(item.url) ? <h1>{item.label}</h1> : <p>{item.label}</p>}
            </Link>
          )
        })}
        <div className={styles.liMore}>
          <span>更多</span>
          <ul className={styles.moreUl}>
            {navList.slice(count, navList.length).map((item, index) => {
              return (
                <Button
                  link
                  href={item.url}
                  key={index}
                  className={cm(styles.liItem, isCurrUrlActive(item.url) ? styles.liItemActive : '')}>
                  {item.label}
                </Button>
              )
            })}
          </ul>
        </div>
      </nav>
    </>
  )
}

export default Index
