import CryptoJS from 'crypto-js'

// aes加密
export const encryptAES = (data, key) => {
  const paddedKey = CryptoJS.enc.Utf8.parse(key)
  const encrypted = CryptoJS.AES.encrypt(data, paddedKey, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.toString()
}

// md5加密
export const hashMD5 = data => {
  return CryptoJS.MD5(data).toString()
}


//sha
export const hashSha256= data => {
  return CryptoJS.SHA256(data).toString()
}


export const processURL = (reqUrl, key)=> {
    // 从请求中获取完整URL
    let rawURL = reqUrl;

    // 移除协议部分 "https://" 和 "http://"
    let obscuredURL = rawURL.replace("https://", "").replace("http://", "");

    // 查找第一个 '/' 和 '?' 位置，混淆处理
    let firstSlash = obscuredURL.indexOf('/');
    let firstQuestionMark = obscuredURL.indexOf('?');

    // 如果没有 '/' 则返回 false
    if (firstSlash === -1) {
        return false;
    }

    // 根据是否存在 '?' 进行字符串切割
    if (firstQuestionMark === -1) {
        obscuredURL = obscuredURL.slice(firstSlash);
    } else {
        obscuredURL = obscuredURL.slice(firstSlash, firstQuestionMark);
    }

    // 拼接签名基础字符串
    let obscuredSecret = key + obscuredURL;

    // 将字符串转为字节数组并进行 base64 编码
    let encodedBase64Key = Buffer.from(obscuredSecret).toString('base64');

    return encodedBase64Key;
}
