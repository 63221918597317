export const stringifyUrlParams = url => {
  const urlArr = url.split('?')
  if (urlArr.length === 1) {
    return url
  }
  return urlArr[0] + '&' + urlArr[1]
}

export const hasFrom = url => {
  try {
    const urlObj = new URL(url)
    const params = urlObj.searchParams
    console.log(url, params.has('from'))
    return params.has('from')
  } catch (error) {
    return false
  }
}
