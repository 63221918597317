import styles from './index.module.scss'
import { useScrollVisible } from '@/utils/hooks'
import { useRouter } from 'next/router'
import cm from 'classnames'
import { useMemo, memo } from 'react'
import Nav from './nav'
import RightMenu from './right-menu'
import Button from '../button'

const noBgArr = ['/download']
const urlArr = [
  '/',
  '/movie',
  '/drama',
  '/variety',
  '/animation',
  '/sports',
  '/vip',
  '/vod/detail/[id]',
  '/person/[id]',
  '/points'
]
const transparentArr = ['/vod/play-thrid/[id]/[ep]', '/vod/play-official/[id]/[ep]', '/live/[id]', '/sports/live/[id]']

const Index = ({ className, url, style,isPlay,vipData}) => {
  const router = useRouter()
  const { scrollProgress } = useScrollVisible()

  //背景色
  const backgroundMemo = useMemo(() => {
    if (noBgArr.includes(router.pathname)) {
      return 'transparent'
    }
    if (transparentArr.includes(router.pathname)) {
      return 'linear-gradient(var(--bg-color),rgba(0,0,0,0))'
    } else if (!urlArr.includes(router.pathname)) {
      return 'rgba(20,20,20,1)'
    }
    return !scrollProgress ? 'linear-gradient(var(--bg-color),rgba(0,0,0,0))' : `rgba(20,20,20,${scrollProgress})`
  }, [scrollProgress, router.pathname])

  
  return (
    <header className={cm(styles.header, className,isPlay)} style={{ ...style, background: backgroundMemo }}>
      <Button link href="/" className={styles.img}>
        {router.pathname === '/' ? (
          <h1>
            <img loading="lazy" src="/api/images/ico.png" alt="华视界" />
            </h1>
        ) : (
          <h1>
          <img loading="lazy" src="/api/images/ico.png" alt="华视界" />
          </h1>
        )}
      </Button>
      <Nav currentUrl={url} />
      <RightMenu vipData={vipData} />
    </header>
  )
}

export default memo(Index)
