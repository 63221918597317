/**
 * 收藏与历史相关
 * */
import request from '@/services/request'

// 收藏相关 //

// 获取点播收藏记录
export const getVodCollectList = async (data, token) => {
  return await request.get('/user/dianbo/collect', data, {
    headers: { token }
  })
}
// 点播是否已收藏
export const getVodCollected = async (id, token) => {
  return await request.get(`/user/dianbo/collect/${id}`, {}, { headers: { token } })
}

// 收藏点播
export const vodCollected = async (id, token) => {
  return await request.post('/user/dianbo/collect', { id }, { headers: { token } })
}

// 取消收藏点播
export const vodCancelCollected = async (id, token) => {
  return await request.delete('/user/dianbo/collect', { id }, { headers: { token } })
}

// 获取直播收藏记录
export const getLiveCollectList = async (data, token) => {
  return await request.get('/web/v1/zhibo/collect', data, {
    headers: { token }
  })
}

// 直播是否已收藏
export const getLiveCollected = async (id, token) => {
  return await request.get(`/user/zhibo/collect/${id}`, {}, { headers: { token } })
}

// 收藏直播
export const liveCollected = async (id, token) => {
  return await request.post('/user/zhibo/collect', { id }, { headers: { token } })
}

// 取消收藏直播
export const liveCancelCollected = async (id, token) => {
  return await request.delete('/user/zhibo/collect', { id }, { headers: { token } })
}

// 历史相关 //
// 获取点播历史记录
export const getVodHistoryList = async (data, token) => {
  return await request.get('/user/dianbo/record', data, {
    headers: { token }
  })
}
// 获取点播播放进度
export const getVodHistoryDuration = async (data, token) => {
  return await request.get('/user/dianbo/recordByCid', data, {
    headers: { token }
  })
}

// 上报点播历史记录
export const reportVodHistory = async (data, token) => {
  return await request.post('/user/dianbo/record', data, {
    headers: { token }
  })
}
// 获取直播历史记录
export const getLiveHistoryList = async (data, token) => {
  return await request.get('/web/v1/zhibo/record', data, { headers: { token } })
}
// 上报直播历史记录
// export const reportLiveHistory = async (data, token) => {
//   return await request.post('/user/zhibo/record', data, {
//     headers: { token }
//   })
// }
