import request from '@/services/request'

// 获取体育项目
export const getProject = async (options = {}) => {
  console.log('======获取体育项目=====')
  const res = await request.get('/sport/project', {}, options)
  return res
}

// 获取体育项目下赛事
export const getCompetition = async params => {
  return await request.get('/sport/competition', params)
}

// 获取热门赛程
export const getHotRace = async () => {
  console.log('======获取热门赛程=====')
  return await request.get('/sport/getHotMatch')
}

// 获取赛事节目单
export const getMatchListByTime = async params => {
  return await request.get('/sport/getMatchListByEffectiveTime', params)
}

// 获取比赛有效天数
export const getEffectiveTime = async params => {
  return await request.get('/sport/effectiveTime', params)
}

// 获取header头图
export const getHeader = async () => {
  return await request.get('/sport/header')
}

// 获取焦点赛事
export const getFocusMatch = async token => {
  return await request.get('/sport/user/focusMatch', {}, { headers: { token } })
}

// 获取播放地址
export const getPlayUrl = async (params, options = {}) => {
  return await request.get('/sport/purl', params, options)
}

// 预约赛事
export const orderFocus = async (params, token) => {
  return await request.get('/sport/user/focus', params, {
    headers: { token }
  })
}

//取消预约赛事
export const cancelOrderFocus = async (params, token) => {
  return await request.delete('/sport/user/focus', params, {
    headers: { token }
  })
}

//获取节目详情
export const getRaceDetail = async (id, token) => {
  const options = {
    headers: token ? { token } : {}
  }
  return await request.get(`/sport/detail/${id}`, {}, options)
}

// 获取赛事描述
export const getCompetitionDesc = async id => {
  return await request.get(`/sport/getCompetitionsDesc/${id}`)
}

//获取赛事映射
export const getRaceUrlMap = async (options = {}) => {
  return await request.get('/sport/getIdAndUrlMapping', {}, options)
}
export const getUrlRedict = async (data, options = {}) => {
  return await request.post('/api/sys/realSite', data, options)
}
