import styles from './index.module.scss'
import { useRouter } from 'next/router'
import Link from 'next/link'
import cm from 'classnames'
import { useRecoilValue } from 'recoil'
import { userInfo } from '@/store'
import { useShowLogin } from '@/utils/hooks'
import Nav from './nav'
import { memo } from 'react'

const Index = ({ className, style }) => {
  const user = useRecoilValue(userInfo)
  const router = useRouter()
  const setShowLogin = useShowLogin()
  //登录
  const loginIn = e => {
    e.stopPropagation()
    if (user?.isLogin) {
      router.push('/userinfo')
    } else {
      setShowLogin(true)
    }
  }

  return (
    <>
      <header className={cm(styles.header, className)} style={{ ...style }}>
        <Link href="/" className={styles.left}>
          {router.pathname === '/' ? (
            <h1>
              <img loading="lazy" src="/api/images/ico.png" alt="华视界" className={styles.img} />
            </h1>
          ) : (
            <h1>
              <img loading="lazy" src="/api/images/ico.png" alt="华视界" className={styles.img} />
            </h1>
          )}
        </Link>
        <div className={styles.right}>
          <Link href="/search-phone">
            <img loading="lazy" className={styles.searchIcon} src="/api/images/h-search" alt="搜索" />
          </Link>
          {typeof user?.token !== 'undefined' && user.isLogin && (
            <Link href="/vip">
              <img loading="lazy" alt="vip" className={cm(styles.searchIcon)} src="/api/images/vip2.png" />
            </Link>
          )}

          <Link href="/download">
            <img loading="lazy" className={styles.searchIcon} src="/api/images/down" alt="下载" />
          </Link>

          <Link href="https://t.me/+CKkE1hC7_3dlYTk1" rel="nofollow" target="_blank">
            <img loading="lazy" className={styles.searchIcon} src="/api/images/join" alt="加入社群" />
          </Link>
          <img
            loading="lazy"
            alt="用户头像"
            className={styles.rightUser}
            onClick={loginIn}
            src={user.isLogin ? '/api/images/user_head_portrait' : '/api/images/user'}
          />
          <Nav />
        </div>
      </header>

      {/* <div className={styles.empty}>

    </div> */}
    </>
  )
}

export default memo(Index)
