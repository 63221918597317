import request from '@/services/request'

// 获取相关推荐
export const getRecommendInfo = async (id, options = {}) => {
  console.log("=======获取相关推荐=======",options)
  return await request.get(`/v3/web/api/recommend/${id}`, {}, options)
}

// 点播观看上报
export const reportVodData = async cid => {
  return await request.post('/metrics/v1/play', { cid })
}

// 获取点播下有哪些演员
export const getRelatedActor = async (cid, options = {}) => {
  return await request.get(`/metadata/v1/collection/actors/${cid}`, {}, options)
}

// 获取演员所选择的专辑
export const getCollectionFromActor = async (id, options = {}) => {
  return await request.get(`/metadata/v1/actor/collection/${id}`, {}, options)
}

// 获取演员信息
export const getActorInfo = async (id, options = {}) => {
  return await request.get(`/metadata/v1/actor/info/${id}`, {}, options)
}

// 搜索演员信息
export const getActorInfoBySearch = async (word, options = {}) => {
  return await request.get('/metadata/v1/actor/searchActor', { word }, options)
}

// 多线路获取&&专辑详情信息
export const getVodCollection = async (cid, options) => {
  const res =  await request.get(`/web/v2/dianbo/collection/${cid}`, {}, options)


  // console.log("===专辑详情信息===",res.data)

  return res
}

// 老id转换为新id
export const oldIdToNewId = async cid => {
  return await request.get(`/web/v2/dianbo/collection/transfer/${cid}`)
}

// 获取最佳线路
export const getVipLine = async (cid, params) => {
  const res =  await request.get(`/web/v2/dianbo/vcollection/${cid}`, params)
  console.log("===获取最佳线路===",res.data)
  return   res;
}

//点播求片
export const dianboGetSource = async data => {
  return await request.post('/metadata/v1/collection/video/record', data)
}

// 通过eporder cid获取播放url
export const fetchPlayUrl = async data => {
  return await request.post('/web/v2/dianbo/video/line', data)
}
