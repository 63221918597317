import styles from './index.module.scss'
import { useState } from 'react'
import cm from 'classnames'
import { useRouter } from 'next/router'

const Index = ({ searchArr, topData, setText, className, clear }) => {
  const router = useRouter()
  const [currIndex, setCurrIndex] = useState(0)
  const switchSearch = e => {
    e.stopPropagation()
    if (currIndex == topData.length - 1) {
      setCurrIndex(0)
      return
    }
    setCurrIndex(currIndex + 1)
  }

  const toSearch = text => {
    setText(text)
    if (router.pathname === '/search') {
      router.replace('/search?word=' + text)
    } else {
      router.push('/search?word=' + text)
    }
  }

  const toPlay = item => {
    if (item.label === '直播') {
      let url = `/live/${item.url ? item.url : ''}`
      if (router.pathname === '/live/[id]') {
        router.replace(url)
      } else {
        router.push(url)
      }
    } else {
      let url = `/vod/detail/${item.id}`
      if (router.pathname === '/vod/detail/[id]') {
        router.replace(url)
      } else {
        router.push(url)
      }
    }
  }

  const deleteHistory = e => {
    e.stopPropagation()
    clear()
    localStorage.setItem('keywords', '[]')
  }
  if (!searchArr.length && !topData.length) return null
  return (
    <div className={cm(styles.searchBox, className)}>
      {searchArr.length ? (
        <>
          <div className={styles.menu}>
            <span>历史记录</span>
            <div className={styles.menuBtn} onClick={deleteHistory}>
              <span>删除</span>
              <img loading="lazy" alt="删除" src="/api/images/delete" />
            </div>
          </div>
          <div className={styles.searchList}>
            {searchArr.map(item => (
              <div className={styles.item} key={item} onClick={() => toSearch(item)}>
                {item}
              </div>
            ))}
          </div>
        </>
      ) : null}
      {topData.length ? (
        <>
          <div className={styles.menu}>
            <span>热门搜索</span>
            {topData.length > 1 ? (
              <div className={styles.menuBtn} onClick={switchSearch}>
                <span>换一批</span>
                <img loading="lazy" alt="切换" src="/api/images/refresh" />
              </div>
            ) : null}
          </div>
          {topData.length
            ? topData[currIndex].map(item => {
                return (
                  <div className={styles.hotSearch} key={item.index} onClick={() => toPlay(item)}>
                    <p
                      className={cm(styles.hotIndex, {
                        [styles.one]: item.index === 1,
                        [styles.two]: item.index === 2,
                        [styles.three]: item.index === 3
                      })}
                    >
                      {item.index}
                    </p>
                    <p className={cm(styles.hotName, 'textEllipsis')}>{item.name}</p>
                    {item.label ? (
                      <p className={cm(styles.hotLabel, item.label === '直播' ? styles.hotLabelLive : '')}>
                        {item.label}
                      </p>
                    ) : null}
                  </div>
                )
              })
            : null}
        </>
      ) : null}
    </div>
  )
}

export default Index
