/*
 * 埋点上报相关
 */

/**
 * 下载事件
 * home_tv_download:下载页首页tv下载
 * home_phone_download 下载页首页phone直接下载
 * guide_usb_tv_download  下载页u盘指南页tv下载
 * guide_file_tv_download 下载页send to file指南页 tv下载
 * h5_tv_download h5的tv下载
 * h5_phone_download h5的phone下载
 * */
export const downloadReport = fileName => {
  gtag('event', 'file_download', {
    event_category: 'Download',
    event_label: fileName,
    value: 1
  })
}

// 分享事件
// export const shareReport = name => {
//   gtag('event', 'share', {
//     method: 'google',
//     content_type: name,
//     item_id: 'G-JGTM-MTQKK7GF',
//     event_category: 'hlove_share',
//     event_label: name,
//     value: 1
//   })
// }

// 会员页开通vip
export const buyVipReport = data => {
  gtag('event', 'buy_VIP', data)
}

// 点播播放
export const vodPlayExportReport = data => {
  gtag('event', 'vod_playing', data)
}

// 点播开通vip
export const vodOpenVipExport = ({ content_name, t_tag, c_tag, p_tag, login_type, vod_vip_remind }) => {
  gtag('event', 'vod_vipclick', {
    content_name,
    t_tag,
    c_tag,
    p_tag,
    login_type,
    vod_vip_remind
  })
}

//播放控制点击事件
export const vodActionExport = ({ speed, road, episode, content_name }) => {
  gtag('event', 'vod_actionclick', {
    speed,
    road,
    episode,
    content_name
  })
}

//直播播放
export const channelPlayReport = ({ channel_group_name, channel_name, playback_state }) => {
  gtag('event', 'channel_player_playing', {
    channel_group_name,
    channel_name,
    playback_state
  })
}

//直播播放点击收藏事件
export const channelClickReport = ({ channel_name }) => {
  gtag('event', 'channel_action_click', {
    channel_name
  })
}

//直播开通vip
export const channelOpenVipExport = ({ channel_name, login_type, channel_vip_remind }) => {
  gtag('event', 'channel_vipclick', {
    channel_name,
    login_type,
    channel_vip_remind
  })
}

// 登录成功上报
export const loginSuccessReport = data => {
  gtag('event', 'login_success', data)
}

// 支付成功上报
export const paySuccessReport = data => {
  gtag('event', 'pay_success', data)
  gtag('event', 'purchase', data)
}

// 用户点击了套餐弹窗

export const openPayModalReport = data => {
  gtag('event', 'payment_display', data)
}

// 点播详情页分享上报
export const vodDetailReport = data => {
  gtag('event', 'vodshare_click', data)
}

// 直播页分享上报
export const liveReport = data => {
  gtag('event', 'channelshare_click', data)
}

// 积分兑换弹窗/我的页面上报
export const scorePageReport = data => {
  gtag('event', 'points_page', data)
}
// 活动首页点击上报
export const scorePageClickReport = data => {
  gtag('event', 'points_click', data)
}

// 活动兑换点击上报
export const scorePageConvertReport = data => {
  gtag('event', 'exchange_click', data)
}

// 活动完成上报
export const scoreTaskFinishedReport = data => {
  gtag('event', 'points_completed', data)
}

// 拉新活动点击上报
export const activeClickReport = data => {
  gtag('event', 'invite_user_page', data)
}
