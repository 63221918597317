import request from '@/services/request'

// 新建自建源
export const createSelfSource = async (data, token) => {
  return await request.post('/user/source/manage', data, {
    headers: { token }
  })
}

// 获取发布自建源
export const getPublishSource = async token => {
  return await request.get('/user/source/manage', {}, { headers: { token } })
}

// 获取自建源管理单个内容
export const getChannelInfo = async (id, token) => {
  return await request.get(`/user/source/manage/${id}`, {}, { headers: { token } })
}
// 更新自建组单个内容
export const updateChannelInfo = async (data, token) => {
  return await request.put('/user/source/manage', data, {
    headers: { token }
  })
}

// 删除自建组
export const deletePublishSource = async (id, token) => {
  return await request.delete(`/user/source/manage/${id}`, {}, { headers: { token } })
}

// 获取导入的自建组
export const getImportSource = async token => {
  return await request.get('/user/source', {}, { headers: { token } })
}

// 导入自建组内容
export const importSelfSource = async (id, token) => {
  return await request.get(`/user/source/import/${id}`, {}, { headers: { token } })
}

// 删除导入了的自建组
export const deleteImportSource = async (id, token) => {
  return await request.delete(`/user/source/${id}`, {}, { headers: { token } })
}

// 修改自建组内的链接状态
export const switchChannelStatus = async (data, token) => {
  return await request.post('/user/source/alive', data, {
    headers: { token }
  })
}

// 判断自建源是否已导入
export const isChannelImported = async (id, token) => {
  return await request.get(`/user/source/added/${id}`, {}, { headers: { token } })
}
